// -----[Media Queries]-----

=xw
    @media only screen and (min-width: "1419px")
        @content
// Wide Screen
=w
    @media only screen and (max-width: "1419px")
        @content
// X-Large Desktop
=x
    @media only screen and (max-width: "1339px")
        @content
// Desktop
=d
    @media only screen and (max-width: "1179px")
        @content
// Tablet
=t
    @media only screen and (max-width: "1023px")
        @content
// Mobile
=m
    @media only screen and (max-width: "767px")
        @content
=a
    @media only screen and (max-width: "639px")
        @content
// Small
=s
    @media only screen and (max-width: "474px")
        @content

// -----[Fonts]-----
=inter
    font-family: 'Inter', sans-serif

// -----[Theme]-----
=dark
    @at-root :global(.dark-mode) &
        @content

=dark-body
    @at-root .dark-mode
        @content

=dark-common
    @at-root .dark-mode &
        @content

// -----[Colors]-----
// Primary
$p1: #2A85FF
$p2: #83BF6E
$p3: #FF6A55
$p4: #8E59FF

// Secondary
$s1: #FFBC99
$s2: #CABDFF
$s3: #B1E5FC
$s4: #B5E4CA
$s5: #FFD88D
$white: #ffffff

// Neutrals
$n0: #ffffff
$n1: #FCFCFC
$n2: #F4F4F4
$n3: #efefef
$n4: #6F767E
$n5: #33383F
$n6: #272B30
$n7: #1A1D1F
$n8: #111315

// Shades
$sh4-75: #9a9fa5bf

// Shades (Dark)
$sh4-50: #6f767e80
$sh8-over7-50: #11131580
$sh4-40: #6f767e66

$n1-glass: linear-gradient(-20deg,transparentize($n1, 0.1),transparentize($n1,0.1))
$n7-glass: linear-gradient(-20deg,transparentize($n7,0.2),transparentize($n7,0.2))

// -----[Effects]-----
=dotted-bg
    background: linear-gradient(90deg,$n2 15px,transparent 1%) 50%,linear-gradient($n2 15px,transparent 1%) 50%,transparentize($n4, 0.4)
    background-size: 16px 16px
    +dark
        background: linear-gradient(90deg,$n8 15px,transparent 1%) 50%,linear-gradient($n8 15px,transparent 1%) 50%,$n5
        background-size: 16px 16px

=frosted-glass
    backdrop-filter: saturate(180%) blur(20px)
    -webkit-backdrop-filter: saturate(180%) blur(20px)
    -moz-backdrop-filter: saturate(180%) blur(20px)
    -ms-backdrop-filter: saturate(180%) blur(20px)
    -o-backdrop-filter: saturate(180%) blur(20px)

=depth-1
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    backdrop-filter: blur(32px)

=button-effect
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
    +dark
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)

// -----[Typography]-----

=h1
    font-size: 1.875rem
    line-height: 3rem
    font-weight: 600
    letter-spacing: -.03em

=h2
    font-size: 1.5rem
    line-height: 2.75rem
    font-weight: 600
    letter-spacing: -.03em

=h3
    font-size: 1.25rem
    line-height: 2.5rem
    font-weight: 600
    letter-spacing: -.02em

=h4
    font-size: 1.125rem
    line-height: 2.25rem
    font-weight: 600
    letter-spacing: -.03em

=title-semibold-1
    font-size: 1.25rem
    line-height: 1.3rem
    font-weight: 600
    letter-spacing: -.02em

=title-medium-1
    font-size: 1.25rem
    line-height: 1.3rem
    font-weight: 500
    letter-spacing: -.02em

=base-semibold-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: 600
    letter-spacing: -.01em

=base-bold-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: bold
    letter-spacing: -.01em

=base-2
    font-size: 0.875rem
    line-height: 1.5rem
    font-weight: 600
    letter-spacing: -.01em

=body-medium-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: 500
    letter-spacing: -.015em

=body-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: 400
    letter-spacing: normal

=body-semibold-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: 600
    letter-spacing: -.01em

=body-semibold-2
    font-size: 0.875rem
    line-height: 1.5rem
    font-weight: 600
    letter-spacing: -.01em

=caption-1
    font-size: 0.813rem
    line-height: 1rem
    font-weight: 600
    letter-spacing: -.01em

=caption-medium-1
    font-size: 0.813rem
    line-height: 1rem
    font-weight: 500
    letter-spacing: -.01em

=caption-2
    font-size: 0.75rem
    line-height: 1rem
    font-weight: 700
    letter-spacing: -.01em

=caption-medium-2
    font-size: 0.75rem
    line-height: 0.75rem
    font-weight: 500
    letter-spacing: -.01em

=button-1
    font-size: 0.938rem
    line-height: 1.5rem
    font-weight: bold
    letter-spacing: -.01em

=button-2
    font-size: 0.813rem
    line-height: 1.5rem
    font-weight: bold
    letter-spacing: -.01em

// -----[Triangle]-----
/// Creates a triangle.
///
///
/// @param {number} $width
///     The width of the triangle.
/// @param {number} $height
///     The height of the triangle.
/// @param {string} $bg
///     The color of the triangle.
/// @param {string} $direction
///     The direction of the triangle.
/// Directions:
///     t => Top
///     r => Right
///     b => Bottom
///     l => Left
///     tl => Top Left
///     tr => Top Right
///     bl => Bottom Left
///     br => Bottom Right
=arr($width, $height, $bg, $direction)
    width: 0px
    height: 0px
    border-style: solid
    @if $direction == t
        border-width: 0 $width / 2 + px $height + px $width / 2 + px
        border-color: transparent transparent $bg transparent
    @if $direction == r
        border-width: $height / 2 + px 0 $height / 2 + px $width + px
        border-color: transparent transparent transparent $bg
    @if $direction == b
        border-width: $height + px $width / 2 + px 0 $width / 2 + px
        border-color: $bg transparent transparent transparent
    @if $direction == l
        border-width: $height / 2 + px $width + px $height / 2 + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == tl
        border-width: $height + px $width + px 0 0
        border-color: $bg transparent transparent transparent
    @if $direction == tr
        border-width: 0 $width + px $height + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == br
        border-width: 0 0 $height + px $width + px
        border-color: transparent transparent $bg transparent
    @if $direction == bl
        border-width: $width + px 0 0 $height + px
        border-color: transparent transparent transparent $bg
// Example:
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// -----[Placeholder]-----
=placeholder
    &::placeholder
        @content

// -----[Media Query Width]-----
=r($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

// -----[Properties]-----
=text-overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

$transition: 0.2s
