.burger
    display: none
    +t
        display: block
        position: relative
        z-index: 15

        width: 40px
        height: 40px
        background: none
        -webkit-tap-highlight-color: rgba(0,0,0,0)
        &.active
            &:before
                transform: translateY(0) rotate(-45deg)
            &:after
                transform: translateY(0) rotate(45deg)
        &:before,
        &:after
            content: ''
            position: absolute
            top: 50%
            left: 25%
            width: 20px
            height: 2px
            background: $n4
            border-radius: 2px
            transition: transform .2s
            transform: translate(-25%,-50%)

        &:before
            transform: translateY(-4px)
        &:after
            transform: translateY(3px)
