[class^="section"]
    margin-bottom: 136px
    +d
        margin-bottom: 112px
    +m
        margin-bottom: 64px

.section-mb0
    margin-bottom: 0
    +d
        margin-bottom: 0
    +m
        margin-bottom: 0

.section-mb64
    margin-bottom: 64px
    +d
        margin-bottom: 64px

.section-mb80
    margin-bottom: 80px
    +d
        margin-bottom: 64px

.section-pd
    padding: 136px 0
    +d
        padding: 112px 0
    +m
        padding: 64px 0

.section-bg
    background: $n7
    +dark-common
        background: none
        box-shadow: inset 0 1px 0 $n2