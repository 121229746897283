[class^="button"]
  display: inline-flex
  column-gap: 0.938rem
  justify-content: center
  align-items: center
  height: 48px
  padding: 0 24px
  background: $p1
  border-radius: 0.74rem
  +button-1
  +dark 
    background: $p1
  text-align: center
  color: $n1
  transition: all .2s
  &:active
    transform: scale(0.97)

  &:hover,&:focus-visible
    background: darken($p1, 10)
    +dark 
      background: darken($p1, 10)
  &.disabled
    opacity: .5
    cursor: not-allowed
    pointer-events: none
  svg
    fill: $n1
    width: 1.5rem
    height: 1.5rem
    transition: all .2s

.button-stroke
  background: $n1
  box-shadow: 0 0 0 2px $n3 inset
  color: $n7
  svg
    fill: $n7
  +dark-common
    background: $n7
    box-shadow: 0 0 0 2px $n6 inset
    color: $n1
    svg
      fill: $n1
  &:hover,
  &.active,
  &:focus-visible
    background: $n3
    box-shadow: 0 0 0 2px $n3 inset
    color: $n7
    svg
      fill: $n7
    +dark-common
      background: $n6
      box-shadow: 0 0 0 2px $n6 inset
      color: $n1
      svg
        fill: $n1

.button-black
  background: $n6
  &:hover,
  &.active,
  &:focus-visible
    background: $n7
  +dark-common
    background: $n8
    box-shadow: inset 0 0 0 2px $n7
    &:hover,
    &.active,
    &:focus-visible
      background: $n6

.button-white
  background: $n0
  color: $n8
  svg
    fill: $n8
  &:hover,&:focus-visible
    background: $n3
  +dark-common
    &:hover,&:focus-visible
      background: $n3

.button-small
  height: 40px
  +button-2
  padding: 0 16px

  svg
    width: 1.125rem
    height: 1.125rem

.button-circle-stroke
  flex: 0 0 48px
  width: 48px
  height: 48px
  padding: 0
  border-radius: 50%
  box-shadow: 0 0 0 2px $n3 inset
  background: transparent
  transition: all .2s
  color: $n7
  svg
    fill: $n7

  &:active
    transform: scale(0.9)

  &:hover,&:focus-visible
    background: $n3
    svg
      fill: $n8
  +dark-common
    box-shadow: 0 0 0 2px $n6 inset
    color: $n1
    svg
        fill: $n1
    &:hover,&:focus-visible
      background: $n6


.button-circle-stroke.button-small
  flex: 0 0 40px
  width: 40px
  height: 40px
