@import ./helpers

// common styles
body
    box-sizing: border-box
    min-width: 375px
    background: $n2
    +inter
    font-size: 0.875rem
    line-height: (24/14)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $n7
    overflow-y: auto
    overflow-x: hidden

    +dark-body
        background: $n8
        color: $n2
        &::-webkit-scrollbar-thumb
            background: $n2

a
    text-decoration: none

svg,
img
    vertical-align: middle

.desktop
    &-hide
        +d
            display: none !important
    &-show
        display: none !important
        +d
            display: block !important
    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important
    &-show
        display: none !important
        +t
            display: block !important

.mobile
    &-hide
        +m
            display: none !important
    &-show
        display: none !important
        +m
            display: block !important

// Scrollbar customization, supported on Chrome.
::-webkit-scrollbar
    width: 10px
    height: 10px

::-webkit-scrollbar-thumb
    border-radius: 3px
    border: 4px solid rgba(0, 0, 0, 0)
    background-clip: padding-box
    background: lighten($n5, 11) // color of the scroll thumb.
    +dark
        background: $n2

::-webkit-scrollbar-button
    width: 0
    height: 0
    display: none

::-webkit-scrollbar-corner
    background-color: transparent
