.skeleton-loading
    position: relative
    overflow: hidden !important
    background-color: #cec3bc
    border-radius: 3px
    min-height: 10px

    &::after
        display: block
        content: ""
        position: absolute
        width: 100%
        height: 100%
        transform: translateX(-100%)
        background: linear-gradient(90deg,transparent,rgba(246, 245, 244, 0.6),transparent)
        animation: skeleton-loading 1.8s infinite ease-in-out !important

=skeleton-loading($color: rgba(246, 245, 244, 0.6))
    position: relative
    overflow: hidden
    border-radius: 3px
    min-height: 10px

    &::after
        display: block
        content: ""
        position: absolute
        width: 100%
        height: 100%
        transform: translateX(-100%)
        background: linear-gradient(90deg,transparent, $color,transparent)
        animation: skeleton-loading 1.8s infinite ease-in-out !important

@keyframes skeleton-loading
    100%
        transform: translateX(100%)
